import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NameId } from '@neptune/models/file';
import { ToolbarButtonConfiguration } from '@neptune/models/buttons';

export interface PageToolbarDropdownConfig {
  dropdownFilterBy?: any; // FIXME: typed to any since dont understand typing in stardard-list-page.component
  dropdownFilterPlaceholder?: string;
  dropdownFilterIcon?: string | null;
  dropdownFilterQueryParamKey?: string;
  dropdownFilterElements?: NameId[];
}

export interface PageToolbarSearchConfig {
  searchPlaceholder?: string;
  searchValue?: string;
  searchOn?: boolean;
}

@Component({
  selector: 'app-page-toolbar',
  templateUrl: './page-toolbar.component.html',
  styleUrls: ['./page-toolbar.component.scss']
})
export class PageToolbarComponent implements OnInit {
  // Title
  @Input('title') title: string = 'Set title using: title="Your title"';

  // Top Title
  @Input('topTitle') topTitle: string;

  // Dropdown filter config
  @Input('dropdown') dropdown: PageToolbarDropdownConfig;
  @Input('initialDropdownFilterValue') set initialDropdownFilterValue(value: string) {
    this.dropdownFilterValue = value;
  }

  @Output('dropdownFilter') dropdownFilter: EventEmitter<string> = new EventEmitter();
  public showDropdownFilter: boolean = false;
  public dropdownFilterValue: string;

  // Toggle
  @Input('toggleFilterDescription') toggleFilterDescription: string;
  @Output('toggle') toggle: EventEmitter<boolean> = new EventEmitter();
  @Input('initialToggleFilterStatus') set initialToggleFilterStatus(initialToggleFilterStatus: boolean) {
    this.toggleFilterStatus = initialToggleFilterStatus;
  }

  public toggleFilterStatus: boolean = false;
  public showToggle: boolean = false;

  // Search field
  @Output('search') search: EventEmitter<string> = new EventEmitter();
  public showSearch: boolean = false;
  @Input('searchConfig') searchConfig: PageToolbarSearchConfig;

  // Buttons
  @Input('buttons') buttons: ToolbarButtonConfiguration[];

  @Input('titleIcon') titleIcon: string;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output('onTitleIcon') onTitleIcon: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit() {
    this.showSearch = this.searchConfig.searchOn ? this.search.observers.length > 0 : false;
    this.showDropdownFilter = this.dropdownFilter.observers.length > 0 && this.dropdown.dropdownFilterBy !== undefined;
    this.showToggle = this.toggle.observers.length > 0 && this.toggleFilterDescription !== undefined;
  }

  onSearch(searchString: string) {
    this.search.emit(searchString);
  }

  onDropdownFilterSelected(event) {
    this.dropdownFilter.emit(event);
  }

  onToggleFilterChange() {
    this.toggle.emit(this.toggleFilterStatus);
  }

  onTitleIconClicked() {
    this.onTitleIcon.emit();
  }
}
