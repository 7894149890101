import { Component, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'standard-search-component',
  templateUrl: './standard-search.component.html',
  styleUrls: ['./standard-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StandardSearchComponent {
  @Input()
  search: string;

  @Input()
  placeholder: string = 'Search';

  @Input()
  auto: string = 'on';

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  options: UntypedFormGroup;

  constructor(fb: UntypedFormBuilder) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: 'never'
    });
  }

  eventFocus() {
    console.log('eventFocus');
  }

  eventSearch() {
    this.onSearch.emit({
      search: this.search
    });
  }

  eventChange(event) {
    if (event.code !== 'Enter') {
      this.onChange.emit({
        search: this.search
      });
    }
  }
}
