import { Injectable, ViewContainerRef, ComponentRef, ChangeDetectorRef, OnDestroy, Type } from '@angular/core';
import { StandardListComponent } from './standard-list.component';
import { LoadingModalComponent } from '@neptune/components/loading-modal/loading-modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImportTask, TaskSchedule } from '@neptune/models';

@Injectable()
export abstract class StandardListContainer<T, L extends StandardListComponent<T>> implements OnDestroy {
  entityName = 'Default Value Component';
  searchValue: string;
  prevSearchValue: string;
  buttonName: string;
  tableInstance: L;

  private isRefreshDisabled: boolean = false;

  private componentRef: ComponentRef<unknown>;

  /** Clean up for Observables */
  protected unsubscribeAll = new Subject<void>();

  /**
   * Construct Parent Standard List Page.
   */
  constructor(protected changeDetector: ChangeDetectorRef) {}

  // Angular 8/9 Note: needs to be defined in the component not in the parent, otherwise it will be undefined.
  public abstract container: ViewContainerRef;
  public abstract loadingComponent: LoadingModalComponent;

  ngOnDestroy(): void {
    // clean up all Observable subscribes
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  /**
   * This function returns the status of the refresh button
   */
  get refreshButtonStatus(): boolean {
    return this.isRefreshDisabled;
  }

  /**
   * This function a get View Name.
   *
   * @returns string
   */
  getEntityName(): string {
    return this.entityName;
  }

  /**
   * Initialize and populate table component
   */
  protected onInitViewContainers(): void {
    // Table component
    const componentType = this.getComponentTableBody();

    // note: componentType must be declared within module.entryComponents (deprecated from Angular 10)
    // note: componentFactoryResolver deprecated from Angular v.13
    this.componentRef = this.container.createComponent(componentType as Type<T>);

    // set data component import-list-page (ngrx-datatable)
    this.tableInstance = <L>this.componentRef.instance;
    // allow for initialization of table instance prior to populating
    this.initTableInstance(this.tableInstance);

    this.loadingComponent.startLoading();
    // populate table instance with entities
    this.tableInstance.populate().subscribe({
      next: () => {
        this.loadingComponent.stopLoading();
        this.afterTablePopulated();
      },
      error: e => {
        // console.error(e);
        this.loadingComponent.showError('There was an error loading list. Please try again.');
      }
    });

    this.tableInstance.onStartLoading.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
      this.loadingComponent.startLoading();
    });

    this.tableInstance.onStopLoading.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
      this.loadingComponent.stopLoading();
    });

    this.tableInstance.onRefresh.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
      this.onRefresh();
    });

    this.tableInstance.onAfterTablePopulated.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
      this.afterTablePopulated();
    });

    this.tableInstance.onShowError.pipe(takeUntil(this.unsubscribeAll)).subscribe((msg: string) => {
      this.loadingComponent.showError(msg);
    });
    this.tableInstance.onShowSuccess.pipe(takeUntil(this.unsubscribeAll)).subscribe((msg: string) => {
      this.loadingComponent.showSuccess(msg);
    });
    this.tableInstance.onShowWarning.pipe(takeUntil(this.unsubscribeAll)).subscribe((msg: string) => {
      this.loadingComponent.showWarning(msg);
    });

    this.tableInstance.onRefreshStatusSetted.subscribe((value: boolean) => (this.isRefreshDisabled = value));

    this.changeDetector.detectChanges();
  }

  /**
   * Allow for table/content instance to be initialized prior to calling populate
   *
   * @param L
   */
  protected initTableInstance(list?: L) {}

  protected afterTablePopulated() {}

  /**
   * This method implements callback on filter data search component.
   *
   * param {any} filterValue
   */
  onSearch(filterValue: any): void {
    this.prevSearchValue = this.searchValue;
    this.searchValue = filterValue;
    this.onFilter();
  }

  /**
   * This method allows you to build an event by pressing the main form button.
   *
   * @returns void
   */
  abstract onNewEntityFormDialog(): void;

  /**
   * This method allows to integrate a behavior for the body of information,
   * especially the datatables. According to the required characteristics.
   *
   * @return any
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  abstract onNameTableBodyDescription(): object;

  /**
   * Method used by onFilterData to determine match of list item by search string
   *
   * @param item
   * @param searchValue
   */
  abstract searchFilter(item: T, searchValue: any): boolean;

  protected onFilter() {
    // Apply Search Filter if it exists
    if (this.searchValue) {
      if (this.searchValue && this.searchFilter && this.searchValue !== this.prevSearchValue) {
        this.tableInstance.beforeApplyingSearch();
        this.tableInstance.rowsData = this.tableInstance.list.filter(item => this.searchFilter(item, this.searchValue));
        // After filter, all rows are uncompressed
        this.tableInstance.rowsData.map(row => {
          // @ts-ignore
          if (row.hasOwnProperty('compressed')) {
            ((row as unknown) as TaskSchedule<ImportTask>).compressed = false;
          }
        });
      }
    } else {
      this.resetFilters();
    }
  }

  /**
   * This method allows to determine the content body's load.
   *
   * @returns any
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  getComponentTableBody(): object {
    const tableComponent = this.onNameTableBodyDescription();
    if (tableComponent == null) {
      console.warn('Component for table body was not defined, must define a body.');
    }
    return tableComponent;
  }

  onRefresh() {
    this.loadingComponent.startLoading();
    this.tableInstance.rowsData = [];
    this.tableInstance.list = [];

    this.tableInstance.populate().subscribe({
      next: () => {
        this.afterTablePopulated();
        this.loadingComponent.stopLoading();
        this.isRefreshDisabled = false;
      },
      error: e => {
        console.error(e);
        this.loadingComponent.showError('There was an error loading list. Please try again.');
      }
    });
  }

  private resetFilters() {
    this.tableInstance.rowsData = this.tableInstance.list.filter(item => true);
    // After reset filters, all rows are uncompressed
    this.tableInstance.rowsData.forEach(row => {
      // @ts-ignore
      if (row.hasOwnProperty('compressed')) {
        ((row as unknown) as TaskSchedule<ImportTask>).compressed = false;
      }
    });
  }
}
