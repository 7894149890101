/* eslint-disable no-invalid-this */
import { Injectable, ChangeDetectorRef, Directive } from '@angular/core';
import { StandardListComponent } from './standard-list.component';
import { NameId } from '@neptune/models/file';
import { uniqBy } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { StandardListContainer } from './standard-list-container';
import {
  ToolbarButtonConfiguration,
  MatButtonTypes,
  ButtonConfigType,
  MatButtonColorTypes
} from '@neptune/models/buttons';
import { PageToolbarDropdownConfig, PageToolbarSearchConfig } from './../page-toolbar/page-toolbar.component';

const REFRESH_ICON: string = 'refresh';

/**
 * Abstract class for list component used as part of standard list
 * Example for Component object:
 * 
  @Component({
    selector: 'app-standard-list-page',
    templateUrl: './standard-list-page.component.html',
    styleUrls: ['./standard-list-page.component.scss'],
  })
 */
@Directive()
@Injectable()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class StandardListPage<T, L extends StandardListComponent<T>> extends StandardListContainer<T, L> {
  /**
   * Construct Parent Standard List Page.
   */
  constructor(protected route: ActivatedRoute, protected changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }

  iconViewName: string | undefined;
  placeholder: string;
  toggleBtnLink: string;
  toggleBtnName: string;

  dropdownConfig: PageToolbarDropdownConfig = {};
  dropdownFilterValue: string;

  searchConfig: PageToolbarSearchConfig = {};

  toggleFilterBy: (elem: T) => boolean;
  toggleFilterDescription: string;
  toggleFilterStatus: boolean = false;
  pageToolbarButtons: ToolbarButtonConfiguration[] = [
    {
      name: '',
      icon: REFRESH_ICON,
      buttonType: MatButtonTypes.ICON,
      configType: ButtonConfigType.MAT_BUTTON,
      colorType: MatButtonColorTypes.PRIMARY,
      onClick: this.onRefresh.bind(this),
      disabled: this.refreshButtonStatus
    }
  ];

  public buttonsBottomLeft: ToolbarButtonConfiguration[] = [];
  public buttonsBottomRight: ToolbarButtonConfiguration[] = [];

  get pageToolbarButtonsConfig(): ToolbarButtonConfiguration[] {
    const refreshIndex: number = this.pageToolbarButtons.findIndex((m: any) => m.icon === REFRESH_ICON);
    if (refreshIndex >= 0) {
      this.pageToolbarButtons[refreshIndex].disabled = this.refreshButtonStatus;
    }

    return this.pageToolbarButtons;
  }

  /**
   * Set standard values.
   *
   * param  {string} entityName Entity Name Child Component
   * param  {string} button ToolbarButtonConfiguration object
   * param  {string} iconViewName Icon Code Name Child Component
   */
  init(
    entityName: string,
    buttons: ToolbarButtonConfiguration[],
    iconViewName?: string,
    searchPlaceholder: string = 'Search',
    searchOn: boolean = true
  ) {
    this.entityName = entityName;
    this.pageToolbarButtons = [...this.pageToolbarButtons, ...buttons];
    this.iconViewName = iconViewName;
    this.searchConfig.searchPlaceholder = searchPlaceholder;
    this.searchConfig.searchOn = searchOn;
  }

  updateButtons(buttonsBottomLeft: ToolbarButtonConfiguration[], buttonsBottomRight: ToolbarButtonConfiguration[]) {
    this.buttonsBottomLeft = buttonsBottomLeft;
    this.buttonsBottomRight = buttonsBottomRight;
  }

  setDropdownFilter(placeholder: string, icon: string, queryParamKey: string, filterBy: (element: T) => NameId) {
    this.dropdownConfig.dropdownFilterPlaceholder = placeholder;
    this.dropdownConfig.dropdownFilterBy = filterBy;
    this.dropdownConfig.dropdownFilterIcon = icon;
    this.dropdownConfig.dropdownFilterQueryParamKey = queryParamKey;
  }

  setToggleFilter(filterBy: (elem: T) => boolean, description: string) {
    this.toggleFilterBy = filterBy;
    this.toggleFilterDescription = description;
  }

  setToggleBtn(name: string, link: string, show: boolean = true) {
    this.pageToolbarButtons.push({
      buttonType: MatButtonTypes.FLAT,
      configType: ButtonConfigType.MAT_BUTTON,
      classes: 'canvas-button  ',
      colorType: MatButtonColorTypes.ACCENT,
      name,
      link
    });
  }

  protected setPlaceholder(placeholder: string): void {
    this.placeholder = placeholder;
  }

  protected afterTablePopulated() {
    if (this.dropdownConfig.dropdownFilterBy) {
      const filtered: NameId[] = this.tableInstance.list.map(this.dropdownConfig.dropdownFilterBy);

      if (this.queryFilterParam) {
        filtered.push({
          name: this.queryFilterParam,
          id: 'UNKNOWN'
        });
      }

      this.dropdownConfig.dropdownFilterElements = uniqBy(filtered, 'name').sort((a, b) =>
        a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
      );

      if (this.dropdownConfig.dropdownFilterElements.find((nId: NameId) => nId.name === this.queryFilterParam)) {
        this.onDropdownFilterSelected(this.queryFilterParam);
        this.changeDetector.detectChanges();
      }
    }
  }

  private get queryFilterParam() {
    return this.dropdownConfig.dropdownFilterQueryParamKey
      ? this.route.snapshot.queryParamMap.get(this.dropdownConfig.dropdownFilterQueryParamKey)
      : undefined;
  }

  /**
   * This method implements callback on filter data by dropdown
   * param value
   */
  onDropdownFilterSelected(value: string | null | undefined) {
    this.dropdownFilterValue = value || '';
    this.onFilter();
  }

  onToggleFilterChange(event) {
    this.toggleFilterStatus = event;
    this.onFilter();
  }

  protected onFilter() {
    super.onFilter();

    // Apply Toggle Filter if it exists
    if (this.toggleFilterBy && this.toggleFilterStatus) {
      this.tableInstance.beforeApplyingToggle();
      this.tableInstance.rowsData = this.tableInstance.rowsData.filter((item: T) => this.toggleFilterBy(item));
    }

    // Apply DropdownFilter if it exists
    if (this.dropdownFilterValue) {
      this.tableInstance.beforeApplyingDropdown();
      this.tableInstance.rowsData = this.tableInstance.rowsData.filter(
        item => this.dropdownConfig.dropdownFilterBy(item).name === this.dropdownFilterValue
      );
    }
  }
}
